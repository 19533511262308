
export const containsMaliciousContent = (value) => {

    if (/^(https?:\/\/[^\s]+)$/i.test(value)) {
        return false;
    }

    const sqlInjectionPatterns = [
        /(['";]|(\b(UNION|SELECT|INSERT|DELETE|DROP|EXEC|ALTER|CREATE|TRUNCATE)\b)|(--|#|\/\*))/gi,
    ];

    const xssPatterns = [
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/i,
        /on\w+="[^"]*"/i,
        /javascript:/i,
    ];

    if (sqlInjectionPatterns.some((regex) => regex.test(value))) {
        return true;
    }

    if (xssPatterns.some((regex) => regex.test(value))) {
        return true;
    }

    return false;
};

import { jwtDecode } from "jwt-decode";

export const checkTokenStatus = (token, bufferTime = 60) => {
    if (!token)
        return { expired: true, expiringSoon: true, message: 'No token provided' };

    try {
        const { exp } = jwtDecode(token);
        if (!exp)
            return {
                expired: true,
                expiringSoon: false,
                message: "Token has no 'exp' field",
            };

        const currentTime = Math.floor(Date.now() / 1000);

        const expired = currentTime >= exp;
        const expiringSoon = !expired && exp - currentTime < bufferTime;

        return {
            expired,
            expiringSoon,
            message: expired
                ? 'Token is expired'
                : expiringSoon
                    ? 'Token is expiring soon'
                    : 'Token is valid',
        };
    } catch (error) {
        return {
            expired: true,
            expiringSoon: true,
            message: `Error decoding token: ${error.message}`,
        };
    }
};